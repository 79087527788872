import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { SearchInput } from '../search-input/search-input';

export const SearchBoxStyled = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${spacing.small};
  width: 100%;
  max-width: 350px;

  .Grid {
    inline-size: 100%;
  }

  input {
    inline-size: 100%;
    padding: 12px ${spacing.xsmall};
    border: 0;
    background-color: transparent;
    block-size: 100%;
    margin-block-start: ${spacing.xsmall};
    font-weight: ${fontWeights.fontMedium};
    line-height: ${lineHeights.header4};
    font-size: ${fontSizes.small};
    box-sizing: border-box;
    appearance: none;

    &:focus {
      outline: 0;
    }
  }
`;

export const SearchInputStyled = styled(SearchInput)`
  max-width: 500px;
`;

export const Result = styled.div<{ $show?: boolean }>`
  display: none;
  width: 100%;
  padding-block: 0;
  padding-inline: ${spacing.small};
  background-color: ${colors.white};
  z-index: 4;
  text-align: start;

  &:empty {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 ${spacing.pagePaddingMobile} 0 ${spacing.pagePaddingMobile};
    inset-block-start: 104px;
  }

  h3 {
    margin-block-end: ${spacing.xsmall};
  }

  ${({ $show }) =>
    $show &&
    css`
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s ease-in-out;

      @media screen and (min-width: ${breakpoints.desktop}) {
        inset-block-start: 0;
      }
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacing.small};
  margin-block: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    margin-block: ${spacing.xsmall};
  }
`;
